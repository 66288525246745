<template>
  <div class="s1">
    <div class="s2">
      <!-- provides top header spacing without pushing total height  -->
    </div>
    <div class="pm-tools content-outer">
      <h1>Patient Management</h1>
      <div class="pm-tools-toolbar">
        <div class="pm-toolbar-left">
           <div class="pm-toolwrap">
             patient {{bww}}
           </div>
           <div class="pm-toolwrap">
             surgeon
           </div>
           <div class="pm-toolwrap">
             date
           </div>
           <div class="pm-toolwrap">
             status
           </div>
           <button>Reset</button>
        </div>
        <div class="pm-toolbar-right">
          <p>View</p>
        </div>
      </div>
    </div>
   <!-- <div class="pm-result-header content-outer ">
      <div class="resultTD">Patient Name</div>
      <div class="resultTD">ID</div>
      <div class="resultTD">DOB</div>
      <div class="resultTD">Age</div>
      <div class="resultTD">Gender</div>
      <div class="resultTD">Primary Surgeon</div>
      <div class="resultTD">Upcoming Surgery</div>
      <div class="resultTD">OD Status</div>
      <div class="resultTD">OS Status</div>
    </div> -->
    <div class="s3 pm-results content-outer">
        <!-- <p>foo {{this.$store.state.isFoo}} </p> -->
 


        <div class="">
          <!-- <div v-for="e in 100" :key="e" class="pm-result-wrap">
            <div class="resultTD">Patient Name {{e}}</div>
            <div class="resultTD">ID</div>
            <div class="resultTD">DOB</div>
            <div class="resultTD max100">Age</div>
            <div class="resultTD">Gender</div>
            <div class="resultTD">Primary Surgeon</div>
            <div class="resultTD">Upcoming Surgery</div>
            <div class="resultTD">OD Status</div>
            <div class="resultTD">OS Status</div>
          </div> -->
          <table>
        <thead>
            <th></th>
            <th class="pipe">Patient Name</th>
            <th class="pipe">ID</th>
            <th class="pipe">DOB</th>
            <th class="pipe">Age</th>
            <th class="pipe">Gender</th>
            <th class="pipe">Primary Surgeon</th>
            <th class="pipe">Upcoming Surgery</th>
            <th class="pipe">OD Status</th>
            <th class="">OS Status</th>
            <th></th>
        </thead>
        <tbody>

            <template v-for="e in 100" :key="e" class="xxpm-result-wrap" :class="{'alt': e % 2 === 0}">
              <tr class="row">
                <td class="td-expand"> 
                  <div class="pm-chevron-wrap">
                    <div class="pm-chevron">
                      <helper-icons foo="pm-chevron"></helper-icons>
                    </div>
                  </div>
                  
                </td>
                <td class="pipe">Patient Name</td>
                <td class="pipe">ID</td>
                <td class="pipe">DOB</td>
                <td class="pipe">Age</td>
                <td class="pipe">Gender</td>
                <td class="pipe">Primary Surgeon</td>
                <td class="pipe">Upcoming Surgery</td>
                <td class="pipe">OD Status</td>
                <td class="">OS Status</td>
                <td>...</td>
              </tr>
              <tr>
                <td colspan="11">info</td>
              </tr>
            </template>
            
        </tbody>
          
          </table>

        </div>
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
import HelperIcons from '@/components/HelperIcons.vue';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelperIcons
    // SvgHelper
    // HelloWorld
  },
  data(){
    return{
      bww: 0
    }
  },
  mounted() {
    // window.addEventListener('resize', this.bw);
    // this.bw();
  },
  unmounted() {
    // window.removeEventListener('resize', this.bw);
  },
  computed:{
    
    
  },
  methods: {
    bw: function(){
      let box = document.querySelector('.pm-result-wrap');
let width = box.offsetWidth;
// let height = box.offsetHeight;
  this.bww = width;
    }
  }
}
</script>
