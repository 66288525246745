// import { createStore } from 'vuex'

// // import svgModule from './modules/svg/index.js';

// export default createStore({
//   modules: {
//     // svg:svgModule
//   },
//   state() {
//     return {
//       isLoggedIn: false,
//       isFoo: "FOOME"
//     };
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import { createStore } from 'vuex';

import svgModule from './modules/svg/index.js';

const store = createStore({
  modules: {
    icons: svgModule
  },
  state() {
    return {
      isLoggedIn: false,
      isFoo: "FOOME"
    };
  },
  mutations: {},
  actions: {},
  getters: {
    getCounter(state){
      return state.counter;
    }
  }
});

export default store;
