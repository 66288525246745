<template>
   <svg xmlns="http://www.w3.org/2000/svg" :viewBox="this.$store.state.icons.svgLib[foo].viewBox">
      <g data-name="Layer 2" v-html="this.$store.state.icons.svgLib[foo].path"></g>
    </svg>
</template>

<script>
export default {
  name: 'iconHelper',
  props: {
    foo: {
      type: String,
      required: false
    },
  }
}
</script>