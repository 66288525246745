<template>
  <div id="head">
    <div class="h-left">
      <router-link to="/">
        <div class="h-logo">
          <div class="icon">
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.08 50.24">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path class="cls-1" d="M42.39 16.11h-.84a11.83 11.83 0 0 0-5.26 1.5l-.2.1a20.91 20.91 0 0 0-8.38 9.18 32.26 32.26 0 0 1-1.94 3.24 20.77 20.77 0 0 1-13.25 9c-.29 0-.55.1-.81.13h-.27c-.29 0-.57.07-.86.08l8.35 8.23a9.47 9.47 0 0 0 13.23 0l8.38-8.25A20.16 20.16 0 0 1 28.24 33a27.57 27.57 0 0 0 2.6-4.16c.07-.12.13-.24.19-.36 5 5.8 10.26 5.8 10.55 5.78a9.1 9.1 0 0 0 .81-18.18ZM11 34.18l.41-.07a.58.58 0 0 0 .18 0l.29-.06h.25l.44-.13a1.67 1.67 0 0 0 .3-.08l.27-.08.54-.19.27-.1.62-.25c.2-.08.42-.2.66-.3l.25-.14c.22-.12.44-.23.66-.36a9.9 9.9 0 0 0 1-.63l.42-.3c.19-.13.37-.28.54-.42l.4-.35.3-.24.61-.57c.25-.26.51-.53.74-.8a4 4 0 0 0 .34-.4c.19-.22.37-.45.56-.69l.36-.49c.27-.39.54-.79.8-1.2.13-.22.25-.43.39-.65s.15-.26.21-.37a5.24 5.24 0 0 0 .34-.61 30.81 30.81 0 0 1 2.77-4.44A26.83 26.83 0 0 1 27.69 18l.08-.1.59-.62.12-.12a22.2 22.2 0 0 1 2.61-2.24h.05c.22-.17.46-.32.69-.47a21.34 21.34 0 0 1 2.53-1.47h.07c.2-.08.38-.18.59-.27a17.87 17.87 0 0 1 5.61-1.5l-8.52-8.52a9.48 9.48 0 0 0-13.24 0l-8.53 8.4a19.81 19.81 0 0 1 13 6.28 32.79 32.79 0 0 0-2.87 4.39C15.6 16 9.88 16.1 9.6 16.1h-.13a9.1 9.1 0 0 0-.74 18.19h.87a6.92 6.92 0 0 0 1.36-.1Z"/>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </router-link>
    </div>
    <div class="h-right">
      <div class="h-right-items">
        <div class="h-alert-btn">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.52 21.63">
              <g data-name="Layer 2">
                <path d="m20 15.37-.38-.61a5.51 5.51 0 0 1-.8-2.9v-2a9.34 9.34 0 0 0-.54-3.14 9.07 9.07 0 0 0-1.48-2.67A8.62 8.62 0 0 0 13 1.33a.64.64 0 0 1-.33-.23A3.15 3.15 0 0 0 8.22.75a3.85 3.85 0 0 0-.35.35.76.76 0 0 1-.33.23 8.57 8.57 0 0 0-3.85 2.75A9.34 9.34 0 0 0 2.2 6.75a9.59 9.59 0 0 0-.53 3.14v2a5.52 5.52 0 0 1-.81 2.9l-.37.61a3.3 3.3 0 0 0-.07 3.34 3.17 3.17 0 0 0 1.09 1.15 3.09 3.09 0 0 0 1.67.48h3.48a1.6 1.6 0 0 1 1 .37 3.16 3.16 0 0 0 .27.2 4.08 4.08 0 0 0 4.63 0l.27-.2a1.56 1.56 0 0 1 1-.37h3.48a3.16 3.16 0 0 0 2.75-1.63 3.3 3.3 0 0 0-.06-3.37Zm-2.65 2.93H13a1.59 1.59 0 0 0-1.21.57 2 2 0 0 1-2.88.22 1.52 1.52 0 0 1-.22-.22 1.59 1.59 0 0 0-1.19-.57H3.17a1.21 1.21 0 0 1-.94-1.86l.37-.61a7.64 7.64 0 0 0 1.12-4v-2a6.9 6.9 0 0 1 5-6.79.77.77 0 0 0 .51-.42 1.09 1.09 0 0 1 1.47-.51 1.14 1.14 0 0 1 .51.51.77.77 0 0 0 .51.42 6.9 6.9 0 0 1 5 6.79v2a7.64 7.64 0 0 0 1.12 4l.37.61a1.21 1.21 0 0 1-.36 1.67 1.18 1.18 0 0 1-.58.19Z" data-name="Layer 1"/>
              </g>
            </svg>
          </div>
          <div class="counter">3</div>
        </div>
        <p>Name</p>
      </div>
      <div class="h-right-nav">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <rect class="cls-1" width="24" height="4" rx="1.13"/>
                <rect class="cls-1" y="9" width="24" height="4" rx="1.13"/>
                <rect class="cls-1" y="18" width="24" height="4" rx="1.13"/>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <router-view/>
</template>









<style lang="scss">

 
 @import "styles/main.scss";









.s1 {
    position: fixed;
    top: 0px;
    left: 0px;
    // background-color: #eee;
      width: 100%;
      height: 100%;
      // padding-top: 44px;
      display: flex;
      flex: none;
      flex-direction: column;
// overflow: hidden;
  .s2 {
    margin: 0px;
    // background-color: cyan;-
    height: 44px;
    flex: none;
    // height: 100%;
    // overflow: auto;
    
  }
  .s3 {
    position: relative;
    height: 100%;
    // background-color: darkorange;
    overflow: auto;
    p {
      // height: 500px;
      // background-color: darkseagreen;
    }
  }
}


.s1x {
  // margin-top: 44px;
  position: fixed;
    top: 0px;
    left: 0px;
    max-height: 100%;
    height: 100%;
    width: 190px;
    background-color: #eee;
overflow: auto;

  background-color: blueviolet;
  .s2 {
    background-color: burlywood;
   // height: 100%;
    // overflow: auto;
    p {
      height: 600px;
      background-color: chartreuse;
    }
  }
}

.scroll-container {
  position: fixed;
  top: 0px;
  padding-top: 44px;
  width: 200px;
  // display: flex;
  height: 600px;
  // overflow: auto;
  
  background-color: blue;

  .scroll-container-inner {
    // height: 400px;
    position: fixed;
    top: 0px;
    background-color: red;
    height: 100%;
    width: 100%;
    overflow: auto;
    p {
      display: block;
      background-color: aqua;
      width: 100px;
      height: 600px;
    }
  }
}



</style>
