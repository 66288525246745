
export default {
  namespaced: true,
  state() {
    return {
      counter: 'fooCounter',
      svgLib: {
        '_blank': {
          viewBox: '',
          path: ''
         },
        'pm-chevron': {
          viewBox: '0 0 15.38 8.81',
          path: '<path d="M14 0 7.69 6.04 1.38 0 0 1.44l7.69 7.37 7.69-7.37L14 0z" />'
         }
      }
    };
  },
  mutations: {},
  actions: {},
  getters: {}
};
