import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Plan from '../views/Plan.vue'

const routes = [
  {path: '/', redirect: '/home'},
  {path: '/home', name: 'Home', component: Home,
    meta: {
      title: 'Patient Management',
      metaTags: [
        // {
        //   name: 'description',
        //   content: 'The about page of our example app.'
        // },
        // {
        //   property: 'og:description',
        //   content: 'The about page of our example app.'
        // }
      ]
    },
  },
  {path: '/plan', name: 'Plan', component: Plan, 
      meta: {
        title: 'Surgical Planning'
      },
    }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
